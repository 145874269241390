<template>
    <v-app>
        <v-main class="lautania">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
};
</script>

<style>
.lautania {
    padding: 0 !important;
}

body {
    font-family: "Poppins", Arial, sans-serif !important;
}

h1 {
    font-size: 26px;
    color: #2f3134;
}

h1,
h2 {
    font-family: "Poppins", Arial, sans-serif !important;
    font-weight: normal;
}
h3,
h4,
h5,
h6,
p,
a,
span {
    font-family: "Poppins", Arial, sans-serif !important;
}

p,
a,
span {
    font-size: 12px !important;
}
</style>
