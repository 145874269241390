import Api from "@/helpers/api";
import axios from "axios";

export default {
    namespaced: true,
    state: {
        photos: [],
        total: 0,
    },

    getters: {
        photos(state) {
            return state.photos;
        },
        total(state) {
            return state.total;
        },
    },

    mutations: {
        SET_PHOTOS(state, value) {
            state.photos = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
    },

    actions: {
        async photos({ commit }, credentials) {
            await Api.call(
                "post",
                "/admin/auth/photo/find/by/boat",
                credentials
            )
                .then((response) => {
                    commit("SET_PHOTOS", response.data.data.data);
                    commit("SET_TOTAL", response.data.data.total);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async upload(_, credentials) {
            const accessToken =
                "Bearer " + window.localStorage.getItem("token");
            // console.log(credentials);
            await axios
                .post("/admin/auth/photo/add/by/boat", credentials, {
                    headers: {
                        Authorization: accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },

        async delete(_, credentials) {
            await Api.call("post", "/admin/auth/photo/delete", credentials)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
