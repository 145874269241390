import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue2";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.config.productionTip = false;

Vue.use(CKEditor);

Vue.prototype.$store = store;

store.dispatch("auth/me").finally(() => {
    new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
});
