import axios from "axios";

class Api {
    call(requestType, url, data = null) {
        const accessToken = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = accessToken;
        return new Promise((resolve, reject) => {
            axios[requestType](url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }

    photo(requestType, url, data = null) {
        const accessToken = "Bearer " + window.localStorage.getItem("token");
        axios.defaults.headers.common["Authorization"] = accessToken;
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        return new Promise((resolve, reject) => {
            axios[requestType](url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

// eslint-disable-next-line no-class-assign
export default Api = new Api();
