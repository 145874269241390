import Api from "@/helpers/api";

export default {
    namespaced: true,
    state: {
        prices: [],
        total: 0,
    },

    getters: {
        prices(state) {
            return state.prices;
        },
        total(state) {
            return state.total;
        },
    },

    mutations: {
        SET_PRICES(state, value) {
            state.prices = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
    },

    actions: {
        async prices({ commit }, credentials) {
            await Api.call("post", "/admin/auth/prices", credentials)
                .then((response) => {
                    commit("SET_PRICES", response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async add(_, credentials) {
            await Api.call("post", "/admin/auth/price/add", credentials)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },

        async delete(_, credentials) {
            await Api.call("post", "/admin/auth/price/delete", credentials)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
