import Api from "@/helpers/api";
import router from "@/router";
import axios from "axios";

export default {
    namespaced: true,
    state: {
        locations: [],
        location: "",
        boatLocation: [],
        total: 0,
    },

    getters: {
        locations(state) {
            return state.locations;
        },
        location(state) {
            return state.location;
        },
        total(state) {
            return state.total;
        },
        boatLocation(state) {
            return state.boatLocation;
        },
    },

    mutations: {
        SET_LOCATIONS(state, value) {
            state.locations = value;
        },
        SET_LOCATION(state, value) {
            state.location = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_BOAT_LOCATION(state, value) {
            state.boatLocation = value;
        },
    },

    actions: {
        async locations({ commit }, credentials) {
            await Api.call("post", "/admin/auth/locations", credentials)
                .then((response) => {
                    commit("SET_LOCATIONS", response.data.data.data);
                    commit("SET_TOTAL", response.data.data.total);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async location({ commit }, credentials) {
            await Api.call("post", "/admin/auth/location/find", credentials)
                .then((response) => {
                    commit("SET_LOCATION", response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async boatLocations({ commit }, credentials) {
            await Api.call(
                "post",
                "/admin/auth/location/find/by/boat",
                credentials
            )
                .then((response) => {
                    commit("SET_BOAT_LOCATION", response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async add(_, credentials) {
            await Api.call(
                "post",
                "/admin/auth/location/add/by/boat",
                credentials
            )
                .then(() => {
                    router.push({
                        name: "boat-price-add",
                        params: credentials.boat_id,
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async delete(_, credentials) {
            await Api.call("post", "/admin/auth/location/delete", credentials)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },

        async addLocation(_, credentials) {
            const accessToken =
                "Bearer " + window.localStorage.getItem("token");
            // console.log(credentials);
            await axios
                .post("/admin/auth/location/add", credentials, {
                    headers: {
                        Authorization: accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
        async update(_, credentials) {
            const accessToken =
                "Bearer " + window.localStorage.getItem("token");
            // console.log(credentials);
            await axios
                .post("/admin/auth/location/update", credentials, {
                    headers: {
                        Authorization: accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
