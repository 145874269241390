import Vue from "vue";
import VueRouter from "vue-router";
import LoginLayout from "@/layouts/LoginLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import store from "@/store";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: LoginLayout,
        children: [
            {
                path: "/",
                name: "login",
                component: () =>
                    import(
                        /* webpackChunkName: "login" */ "../views/auth/LoginPage.vue"
                    ),
            },
        ],
    },
    {
        path: "/super",
        component: DashboardLayout,
        children: [
            {
                path: "/",
                name: "dashboard",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/DashboardPage.vue"
                    ),
            },

            {
                path: "/boats",
                name: "boats",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/boats/IndexPage.vue"
                    ),
            },
            {
                path: "/boat/add/:id",
                name: "boat-add",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/boats/AddBoat.vue"
                    ),
            },
            {
                path: "/boat/add/photo/:id",
                name: "boat-photo-add",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/boats/AddPhoto.vue"
                    ),
            },
            {
                path: "/boat/add/price/:id",
                name: "boat-price-add",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/boats/AddPrice.vue"
                    ),
            },
            {
                path: "/locations",
                name: "locations",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/locations/IndexList.vue"
                    ),
            },

            {
                path: "/location/add",
                name: "location-add",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/locations/AddPage.vue"
                    ),
            },

            {
                path: "/location/update/:id",
                name: "location-update",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/locations/UpdatePage.vue"
                    ),
            },

            {
                path: "/hosts",
                name: "hosts",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/hosts/IndexList.vue"
                    ),
            },

            {
                path: "/host/add",
                name: "host-add",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/hosts/AddPage.vue"
                    ),
            },

            {
                path: "/host/update/:id",
                name: "host-update",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/hosts/UpdatePage.vue"
                    ),
            },

            {
                path: "/users",
                name: "users",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/users/IndexList.vue"
                    ),
            },

            {
                path: "/user/add",
                name: "user-add",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/users/AddPage.vue"
                    ),
            },

            {
                path: "/user/update/:id",
                name: "user-update",
                meta: { auth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/users/UpdatePage.vue"
                    ),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.VUE_APP_BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.getters["auth/check"]) {
        next({ name: "login" });
    } else {
        next();
    }

    if (to.name == "login" && store.getters["auth/check"]) {
        next({ name: "dashboard" });
    } else {
        next();
    }
});

export default router;
