import Api from "@/helpers/api";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        boats: [],
        boat: "",
        total: 0,
    },

    getters: {
        boats(state) {
            return state.boats;
        },
        boat(state) {
            return state.boat;
        },
        total(state) {
            return state.total;
        },
    },

    mutations: {
        SET_BOATS(state, value) {
            state.boats = value;
        },
        SET_BOAT(state, value) {
            state.boat = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
    },

    actions: {
        async boats({ commit }, credentials) {
            await Api.call("post", "/admin/auth/boats", credentials)
                .then((response) => {
                    commit("SET_BOATS", response.data.data.data);
                    commit("SET_TOTAL", response.data.data.total);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async add(_, credentials) {
            await Api.call("post", "/admin/auth/boat/add", credentials)
                .then((response) => {
                    router.push({
                        name: "boat-add",
                        params: { id: response.data.data },
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async update(_, credentials) {
            await Api.call("post", "/admin/auth/boat/update", credentials)
                .then((response) => {
                    router.push({
                        name: "boat-photo-add",
                        params: { id: response.data.data.id },
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async detail({ commit }, credentials) {
            await Api.call("post", "/admin/auth/boat/detail", credentials)
                .then((response) => {
                    commit("SET_BOAT", response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async delete(_, credentials) {
            await Api.call("post", "/admin/auth/boat/delete", credentials)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
