<template>
    <v-app dark dense>
        <v-navigation-drawer
            app
            v-model="drawer"
            :mini-variant="miniVariant"
            :clipped="clipped"
            fixed
            dense
            color="#153F6F">
            <v-list-item>
                <v-list-item-content class="py-7">
                    <v-list-item-title class="text-h5 white--text">
                        <h1 class="white--text">Lautania</h1>
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle class="white--text">
                        <span>v.1</span>
                    </v-list-item-subtitle> -->
                </v-list-item-content>
            </v-list-item>

            <v-divider color="gray"></v-divider>
            <v-list>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="item.to"
                    router
                    exact
                    dense>
                    <v-list-item-action>
                        <v-icon color="#ddd">{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">{{
                            item.title
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="clipped" dense fixed app elevation="0">
            <v-app-bar-nav-icon @click.stop="switchDrawer()" />
            <v-spacer />
            <v-btn icon @click="logout()">
                <v-icon>mdi-power</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <v-progress-linear
                v-if="spinner == true"
                indeterminate
                color="yellow darken-2"></v-progress-linear>
            <v-container>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { mapActions } from "vuex";
export default {
    middleware: "auth",
    name: "DefaultLayout",
    data() {
        return {
            clipped: false,
            drawer: true,
            fixed: false,
            items: [
                {
                    icon: "mdi-apps",
                    title: "Beranda",
                    to: "/super",
                },
                {
                    icon: "mdi-alpha-b-box-outline",
                    title: "Boats",
                    to: "/boats",
                },
                {
                    icon: "mdi-alpha-l-box-outline",
                    title: "Locations",
                    to: "/locations",
                },
                {
                    icon: "mdi-alpha-h-box-outline",
                    title: "Hosts",
                    to: "/hosts",
                },
                // {
                //     icon: "mdi-alpha-u-box-outline",
                //     title: "Users",
                //     to: "/users",
                // },
            ],
            miniVariant: false,
            right: true,
            rightDrawer: false,
            title: "Lautania Super Admin v.1.0.0",
        };
    },

    computed: {
        ...mapGetters({ spinner: "general/spinner" }),
    },

    methods: {
        ...mapActions({
            logoutAction: "auth/logout",
        }),

        switchDrawer() {
            console.log(this.drawer);
            this.drawer = !this.drawer;
        },

        async logout() {
            await this.logoutAction();
            this.$router.replace("/");
        },
    },
};
</script>
