import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import boat from "./boat";
import host from "./host";
import photo from "./photo";
import general from "./general";
import location from "./location";
import price from "./price";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        boat,
        host,
        photo,
        general,
        location,
        price,
    },
});
