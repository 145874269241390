export default {
    namespaced: true,
    state: {
        spinner: false,
    },

    getters: {
        spinner(state) {
            return state.spinner;
        },
    },

    mutations: {
        SET_SPINNER(state, value) {
            state.spinner = value;
        },
    },

    actions: {
        async spinner({ commit }, credentials) {
            commit("SET_SPINNER", credentials);
        },
    },
};
