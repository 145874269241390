import Api from "@/helpers/api";
import axios from "axios";

export default {
    namespaced: true,
    state: {
        hosts: [],
        host: "",
        total: 0,
    },

    getters: {
        hosts(state) {
            return state.hosts;
        },
        host(state) {
            return state.host;
        },
        total(state) {
            return state.total;
        },
    },

    mutations: {
        SET_HOSTS(state, value) {
            state.hosts = value;
        },
        SET_HOST(state, value) {
            state.host = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
    },

    actions: {
        async hosts({ commit }, credentials) {
            await Api.call("post", "/admin/auth/hosts", credentials)
                .then((response) => {
                    commit("SET_HOSTS", response.data.data.data);
                    commit("SET_TOTAL", response.data.data.total);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async host({ commit }, credentials) {
            await Api.call("post", "/admin/auth/host/detail", credentials)
                .then((response) => {
                    commit("SET_HOST", response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        async delete(_, credentials) {
            await Api.call("post", "/admin/auth/host/delete", credentials)
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },

        async add(_, credentials) {
            const accessToken =
                "Bearer " + window.localStorage.getItem("token");
            // console.log(credentials);
            await axios
                .post("/admin/auth/host/add", credentials, {
                    headers: {
                        Authorization: accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },

        async update(_, credentials) {
            const accessToken =
                "Bearer " + window.localStorage.getItem("token");
            // console.log(credentials);
            await axios
                .post("/admin/auth/host/update", credentials, {
                    headers: {
                        Authorization: accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
